/**
 * Entrypoint für rollup
 *
 * Dokumentation und Hilfe zu rollup unter rollupjs.org
 *
 * Hinweis: Bei der Verwendung von ES2015 Features wie
 * promises, symbols, collections, iterators, typed arrays etc.
 * muss `core-js` importiert werden!
 *
 * Dokumentation dazu unter https://github.com/zloirock/core-js
 *
 * Wenn man zum Beispiel nur Promises verwenden will muss folgender Code am Anfang dieser Datei stehen:
 *
 *          import 'core-js/fn/promise'
 *
 */
import 'core-js/fn/object/assign'
import domready from 'domready'
import baguetteBox from 'baguettebox.js'
import categoryInit from './modules/kacheln'
import initModal from './modules/modal'
import formSubmit from './modules/form-submit'
import initMaps from './modules/map'
import initToggle from './modules/toggle-container'
import mobileNavigation from './modules/mobile-navigation'
import toggleSub from './modules/toggle-sub-menu'
import initFilter from './modules/filter'
import animateScrollTo from 'animated-scroll-to'
import Swiper from 'swiper'
import notifier from './vendor/notifier'
import debug from './modules/debug.js'
import initAffix from './modules/affix.js'
import initConfiguration from './modules/configuration'
import initDetail from './modules/detail'
// import from '../node_modules/smooth-scroll/dist/smooth-scroll.polyfills'
// import noUiSlider from 'nouislider'
/**
 * Ab geht die Reise
 */
domready(() => {
  if (document.getElementById('message-notifer')) {
    notifier.show(document.getElementById('message-notifer').getAttribute('data-message'), '', document.getElementById('message-notifer').getAttribute('data-status'), '', 4000)
  }
  mobileNavigation()
  toggleSub()
  categoryInit()
  initModal()
  formSubmit()
  initMaps()
  initToggle()
  initFilter()
  debug()
  initAffix()
  initConfiguration()
  initDetail()

  var galarieCar = document.getElementById('detail__gallery-action')
  if (galarieCar) {
    galarieCar.addEventListener('click', function (e) {
      e.preventDefault()
      animateScrollTo(document.getElementById('detail-gallarie'), {
        offset: -100
      })
      return false
    })
  }
  var dateilInfoButton = document.getElementById('detail-info-button')
  if (dateilInfoButton) {
    dateilInfoButton.addEventListener('click', function (e) {
      e.preventDefault()
      animateScrollTo(document.getElementById('angebots-anfrage'), {
        offset: -100
      })
      return false
    })
  }

  var ctaFilter = document.getElementsByClassName('is--ctafilter')
  if(ctaFilter.length > 0) {
    for (var j = 0; j < ctaFilter.length; j++) {
      ctaFilter[j].addEventListener('click', function (e) {
        e.stopImmediatePropagation()
        let minPrice = 20000
        let maxPrice = 120000

        let data = {
          fMarke: [this.getAttribute('data-her-filter')],
          fHersteller: [],
          fKategorie: [],
          fLaenge: {
            min: 540,
            max: 745
          },
          fPriceNumber: {
            min: minPrice,
            max: maxPrice
          },
          fSchlafplaetze: 2,
          fPersonen: 2,
          fErstzulassung: {
            von: '',
            bis: ''
          },
          fGebraucht: 1,
          fNeuwagen: 1,
          fType: 'BESTAND',
          isSofort: false,
          modus: 'filterListing'
        }

        window.sessionStorage.setItem('filterData', JSON.stringify(data))
      })
    }
  }

  // eslint-disable-next-line
  new Swiper('.swiper-container-cars', {
    slidesPerView: 5,
    spaceBetween: 30,
    centeredSlides: true,
    speed: 2000,
    nextButton: '.car-slide--next',
    prevButton: '.car-slide--prev',
    loop: true,
    breakpoints: {
      768: {
        slidesPerView: 1,
        spaceBetween: 10
      },
      1060: {
        slidesPerView: 2,
        spaceBetween: 10
      },
      1760: {
        slidesPerView: 3,
        spaceBetween: 30
      }
    }
  })
// eslint-disable-next-line
  new Swiper('.leistungen-slider', {
    slidesPerView: 3,
    slidesPerColumn: 1,
    spaceBetween: 0,
    speed: 2000,
    nextButton: '.leistung-button-next',
    prevButton: '.leistung-button-prev',
    loop: true,
    breakpoints: {
      568: {
        slidesPerView: 1,
        spaceBetween: 0
      },
      1024: {
        slidesPerView: 2,
        spaceBetween: 0
      }
    }
  })
  // eslint-disable-next-line
  baguetteBox.run('.gallery')
  // eslint-disable-next-line
  new Swiper('.dleistung__slider', {
    slidesPerView: 3,
    slidesPerColumn: 1,
    spaceBetween: 16,
    speed: 600,
    nextButton: '.swiper-button-next.dleistung__slider--next',
    prevButton: '.swiper-button-prev.dleistung__slider--prev',
    loop: true,
    pagination: '.swiper-pagination.dleistung__slider--pagination',
    paginationType: 'bullets',
    paginationClickable: true,

    breakpoints: {
      568: {
        slidesPerView: 1,
        spaceBetween: 0
      },
      1024: {
        slidesPerView: 2,
        spaceBetween: 16
      }
    }
  })

  // eslint-disable-next-line
  new Swiper('.innenaussen__slider', {
    slidesPerView: 1,
    slidesPerColumn: 1,
    spaceBetween: 0,
    speed: 600,
    nextButton: '.swiper-button-next.innenaussen__next',
    prevButton: '.swiper-button-prev.innenaussen__prev',
    loop: true,
    pagination: '.swiper-pagination.innenaussen__pagination',
    paginationType: 'bullets',
    paginationClickable: true
  })
  // eslint-disable-next-line
  new Swiper('.slider-js', {
    effect: 'fade',
    slidesPerView: 1,
    speed: 600,
    loop: true
  })
  // eslint-disable-next-line
  new Swiper('.start__slider', {
    effect: 'fade',
    autoplay: 4000,
    slidesPerView: 1,
    slidesPerColumn: 1,
    spaceBetween: 0,
    speed: 1000,
    nextButton: '.swiper-button-next.start__slider-next',
    prevButton: '.swiper-button-prev.start__slider-prev',
    loop: true,
    pagination: '.swiper-pagination.innenaussen__pagination',
    paginationType: 'bullets',
    paginationClickable: true
  })
})
