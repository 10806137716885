import selectAll from './select-all'
import baguetteBox from 'baguettebox.js'
import xhr from 'xhr'
import Swiper from 'swiper'

function getItemInfo (id, element) {
  let body = document.getElementById(id).innerHTML
  document.getElementById('modal-body').innerHTML = body
  baguetteBox.run('.gallerymodal')
  modal(element)
}

export function modal (elem) {
  let closeButton = selectAll('.close')
  elem.classList.add('open')
  document.body.classList.add('stop-scrolling')
  if (closeButton !== null) {
    for (var i = closeButton.length - 1; i >= 0; i--) {
      closeButton[i].removeEventListener('click', function () {
      })
      closeButton[i].addEventListener('click', function () {
        elem.classList.remove('open')
        document.body.classList.remove('stop-scrolling')
        if (this.classList.contains('reload')) {
          window.location.reload()
        }
      }, false)
    }
  }
}

export default function initModal () {
  let modals = selectAll('.modal-open')
  if (modals !== null) {
    for (var i = modals.length - 1; i >= 0; i--) {
      modals[i].removeEventListener('click', function () {
      })
      modals[i].addEventListener('click', function (event) {
        event.stopPropagation()
        event.preventDefault()
        let element = document.getElementById(this.getAttribute('data-target'))
        if (this.classList.contains('modal-body')) {
          getItemInfo(this.getAttribute('data-id'), element)
        } else {
          modal(element)
        }
        return false
      }, {passive: false})
    }
  }

  let modals36 = selectAll('.open-360')
  if (modals36 !== null) {
    for (var z = modals36.length - 1; z >= 0; z--) {
      modals36[z].removeEventListener('click', function () {
      })
      modals36[z].addEventListener('click', function (event) {
        event.stopPropagation()
        event.preventDefault()
        let element = document.getElementById('modal-360')
        document.getElementById('modal-body-360').innerHTML = ' <iframe class="modal-iframe" style="height: 400px; width: 100%" frameborder="0" vspace="0" hspace="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" scrolling="auto" src="' + this.getAttribute('data-link') + '"></iframe>'

        modal(element)
        return false
      }, {passive: false})
    }
  }

  let modalsGallery = selectAll('.open-gallery')
  if (modalsGallery !== null) {
    for (var g = modalsGallery.length - 1; g >= 0; g--) {
      modalsGallery[g].removeEventListener('click', function () {
      })
      modalsGallery[g].addEventListener('click', function (event) {
        event.stopPropagation()
        event.preventDefault()
        xhr({
          body: JSON.stringify({
            modus: 'loadCarImage',
            carID: this.getAttribute('data-id')
          }),
          uri: 'ajax.php',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          }
        }, (err, res, body) => {
          if (err) {
            console.log(err)
            return false
          } else {
            let data = JSON.parse(body)
            if (data.status === 'success') {
              let element = document.getElementById('modal-gallery')
              document.getElementById('modal-body-gallery').innerHTML = data.html
              modal(element)
              // eslint-disable-next-line
              new Swiper('.gallery-top', {
                loop: true,
                nextButton: '.gal-next',
                prevButton: '.gal-prev'
              })
            }
          }
        })
        return false
      }, {passive: false})
    }
  }

  let anfrageModal = selectAll('.open-anfrage')
  console.log(anfrageModal)
  if (anfrageModal !== null) {
    for (var a = anfrageModal.length - 1; a >= 0; a--) {
      anfrageModal[a].removeEventListener('click', function () {
      })
      anfrageModal[a].addEventListener('click', function (event) {
        event.preventDefault()
        event.stopPropagation()
        let element = document.getElementById('anfrage-modal')
        modal(element)
      }, {passive: false})
    }
  }
}
