import xhr from 'xhr'
import 'core-js/fn/object/assign'
import jump from 'jump.js'

let data = {
  fMarke: '',
  fKategorie: '',
  fID: '',
  zusatz: [],
  modulPfad: 'test/',
  modus: '',
  name: '',
  mail: '',
  tel: '',
  betreff: '',
  text: ''
}
var step1Event = function () {
  data.modus = 'loadBauformen'
  xhr({
    body: JSON.stringify(data),
    uri: 'ajax.php',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  }, (err, res, body) => {
    if (err) {
      console.log(err)
    } else {
      let response = JSON.parse(body)
      document.getElementById('step-1').classList.add('is--hidden')
      document.getElementById('step-2').innerHTML = response.html
      document.getElementById('step-2').classList.remove('is--hidden')
      document.getElementById('is--prev-button').classList.remove('is--hidden')
      document.getElementById('legend-bottom').classList.add('two')
      document.getElementById('is--prev-button').setAttribute('data-step', 1)
      backButton()
      step2()
    }
  })
}
var step1Eventfn = function (evt) {
  data.fMarke = this.getAttribute('data-id')
  step1Event()
}
var step1 = function () {
  let _marke = document.getElementsByClassName('company-wrapper__box')
  if (_marke.length) {
    for (let i = _marke.length - 1; i >= 0; i--) {
      _marke[i].removeEventListener('click', step1Eventfn)
      _marke[i].addEventListener('click', step1Eventfn)
    }
  }
}
var step2Event = function () {
  data.modus = 'loadModelle'
  xhr({
    body: JSON.stringify(data),
    uri: 'ajax.php',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  }, (err, res, body) => {
    if (err) {
      console.log(err)
    } else {
      let response = JSON.parse(body)
      document.getElementById('step-2').classList.add('is--hidden')
      document.getElementById('step-3').innerHTML = response.html
      document.getElementById('step-3').classList.remove('is--hidden')
      document.getElementById('is--prev-button').setAttribute('data-step', 2)
      step3()
    }
  })
}
var step2Eventfn = function (evt) {
  data.fKategorie = this.getAttribute('data-id')
  step2Event()
}
var step2 = function () {
  let _bauform = document.getElementsByClassName('bauformen-wrapper__box')
  if (_bauform.length) {
    for (let i = _bauform.length - 1; i >= 0; i--) {
      _bauform[i].removeEventListener('click', step2Eventfn)
      _bauform[i].addEventListener('click', step2Eventfn)
    }
  }
}

var step3Event = function () {
  data.modus = 'loadZusatz'
  if (data.fID !== '') {
    xhr({
      body: JSON.stringify(data),
      uri: 'ajax.php',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    }, (err, res, body) => {
      if (err) {
        console.log(err)
      } else {
        let response = JSON.parse(body)
        document.getElementById('step-3').classList.add('is--hidden')
        document.getElementById('step-4').innerHTML = response.html
        document.getElementById('step-4').classList.remove('is--hidden')
        document.getElementById('is--prev-button').setAttribute('data-step', 3)
        let _nextButton = document.getElementsByClassName('is--next-button')
        if (_nextButton.length) {
          for (let i = _nextButton.length - 1; i >= 0; i--) {
            _nextButton[i].setAttribute('data-step', 2)
          }
        }
        step4()
      }
    })
  }
}
var step3Fn = function (evt) {
  let _modell = document.getElementsByClassName('modell-wrapper__box')
  data.fID = this.getAttribute('data-id')
  for (let y = _modell.length - 1; y >= 0; y--) {
    _modell[y].classList.remove('is--selected')
  }
  this.classList.add('is--selected')
}
var step3 = function () {
  let _modell = document.getElementsByClassName('modell-wrapper__box')
  if (_modell.length) {
    for (let i = _modell.length - 1; i >= 0; i--) {
      _modell[i].removeEventListener('click', step3Fn)
      _modell[i].addEventListener('click', step3Fn)
    }
  }
}

var calcZusatz = function (aktID) {
  console.log('Triggered')
  data.modus = 'calcZusatz'
  data.zusatz = []
  let selected = document.getElementsByClassName('zusatz__list__item is--selected')
  if (selected.length) {
    for (let i = 0; i < selected.length; i++) {
      data.zusatz.push(selected[i].getAttribute('data-id'))
    }
  }
  data.clicked = ''
  if (aktID) {
    data.clicked = aktID
  }

  if (data.fID !== '') {
    xhr({
      body: JSON.stringify(data),
      uri: 'ajax.php',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    }, (err, res, body) => {
      if (err) {
        console.log(err)
      } else {
        let response = JSON.parse(body)
        document.getElementById('price-side').innerHTML = response.html

        // let disabled = document.getElementsByClassName('is--disabled')
        // if (disabled.length) {
        //   for (var r = disabled.length - 1; r >= 0; r--) {
        //     disabled[r].classList.remove('is--disabled')
        //   }
        // }
        if (response.excluded) {
          if (response.excluded.length > 0) {
            for (let z = 0; z < response.excluded.length; z++) {
              let elem = document.getElementById('zusatz-' + response.excluded[z])
              if (elem) {
                // elem.classList.add('is--disabled')
                elem.classList.remove('is--selected')
              }
            }
          }
        }
      }
    })
  }
}

var step4Event = function () {
  data.modus = 'loadResult'
  data.zusatz = []
  let selected = document.getElementsByClassName('zusatz__list__item is--selected')
  if (selected.length) {
    for (let i = 0; i < selected.length; i++) {
      data.zusatz.push(selected[i].getAttribute('data-id'))
    }
  }
  if (data.fID !== '') {
    xhr({
      body: JSON.stringify(data),
      uri: 'ajax.php',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    }, (err, res, body) => {
      if (err) {
        console.log(err)
      } else {
        let response = JSON.parse(body)
        document.getElementById('step-4').classList.add('is--hidden')
        document.getElementById('result-step').classList.remove('is--hidden')
        document.getElementById('result-step').innerHTML = response.html
        document.getElementById('is--next-button').classList.add('is--hidden')
        document.getElementById('conf-form').classList.remove('is--hidden')
        document.getElementById('finanzierung-con').classList.remove('is--hidden')
        document.getElementById('is--start-button-bottom').classList.add('is--hidden')
        document.getElementById('legend--steps').classList.add('is--hidden')
        document.getElementById('is--prev-button').setAttribute('data-step', 4)
        document.getElementById('conf-send').addEventListener('click', sendConfig)
        if (document.getElementById('finanzierung-con')) {
          document.getElementById('netto-betrag').innerHTML = (response.zinsen.nettoBetrag)
          document.getElementById('monats-rate').innerHTML = (response.zinsen.monatsRate)
          document.getElementById('finn-gesamt').innerHTML = (response.zinsen.gesamtbetrag)
          document.getElementById('raten-monate').innerHTML = (response.zinsen.laufzeit)
          document.getElementById('laufzeit').value = response.zinsen.laufzeit
        }
      }
    })
  }
}
var step4fn = function (evt) {
  if (!this.classList.contains('is--disabled')) {
    if (this.classList.contains('is--selected')) {
      this.classList.remove('is--selected')
    } else {
      this.classList.add('is--selected')
    }
  }
  calcZusatz(this.getAttribute('data-id'))
}
var step4fsn = function (evt) {
  step4Event()
}
var step4 = function () {
  let zusatz = document.getElementsByClassName('zusatz__list__item')
  if (zusatz.length) {
    for (let i = zusatz.length - 1; i >= 0; i--) {
      zusatz[i].removeEventListener('click', step4fn)
      zusatz[i].addEventListener('click', step4fn)
    }
  }

  let _nextButton = document.getElementsByClassName('is--next-button')
  if (_nextButton.length) {
    for (let i = _nextButton.length - 1; i >= 0; i--) {
      _nextButton[i].removeEventListener('click', step4fsn)
      _nextButton[i].addEventListener('click', step4fsn)
    }
  }
}
var backButtonFnn = function (evt) {
  var step = this.getAttribute('data-step')
  switch (step) {
    case '1':
      step1()
      document.getElementById('step-1').classList.remove('is--hidden')
      document.getElementById('step-2').classList.add('is--hidden')
      document.getElementById('is--prev-button').classList.add('is--hidden')
      document.getElementById('legend-bottom').classList.remove('two')
      break
    case '2':
      document.getElementById('step-2').classList.remove('is--hidden')
      document.getElementById('step-3').classList.add('is--hidden')
      document.getElementById('is--prev-button').setAttribute('data-step', 1)
      break
    case '3':
      document.getElementById('step-3').classList.remove('is--hidden')
      document.getElementById('step-4').classList.add('is--hidden')
      document.getElementById('is--prev-button').setAttribute('data-step', 2)
      break
    case '4':
      document.getElementById('step-4').classList.remove('is--hidden')
      document.getElementById('is--next-button').classList.remove('is--hidden')
      document.getElementById('finanzierung-con').classList.add('is--hidden')
      document.getElementById('conf-form').classList.add('is--hidden')
      document.getElementById('is--start-button-bottom').classList.add('is--hidden')
      document.getElementById('legend--steps').classList.remove('is--hidden')
      document.getElementById('is--prev-button').setAttribute('data-step', 3)
      document.getElementById('result-step').classList.add('is--hidden')
      let _nextButton = document.getElementsByClassName('is--next-button')
      if (_nextButton.length) {
        for (let i = _nextButton.length - 1; i >= 0; i--) {
          _nextButton[i].setAttribute('data-step', 1)
        }
      }
      break
  }
}
var backButton = function () {
  let back = document.getElementById('is--prev-button')
  if (back) {
    back.removeEventListener('click', backButtonFnn)
    back.addEventListener('click', backButtonFnn)
  }
}
var forwardButtonFn = function (evt) {
  var step = this.getAttribute('data-step')
  switch (step) {
    case '1':
      step3Event()
      break
    case '2':
      step4Event()
      break
  }
}
var forwardButton = function () {
  let _nextButton = document.getElementsByClassName('is--next-button')
  if (_nextButton.length) {
    for (let i = _nextButton.length - 1; i >= 0; i--) {
      _nextButton[i].removeEventListener('click', forwardButtonFn)
      _nextButton[i].addEventListener('click', forwardButtonFn)
    }
  }
}

var updateFin = function () {
  data.modus = 'calcFin'
  data.zusatz = []
  let selected = document.getElementsByClassName('zusatz__list__item is--selected')
  if (selected.length) {
    for (let i = 0; i < selected.length; i++) {
      data.zusatz.push(selected[i].getAttribute('data-id'))
    }
  }
  data.monate = document.getElementById('laufzeit').value
  data.anzahlung = document.getElementById('anzahlung').value
  if (data.fID !== '') {
    xhr({
      body: JSON.stringify(data),
      uri: 'ajax.php',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    }, (err, res, body) => {
      if (err) {
        console.log(err)
      } else {
        let response = JSON.parse(body)
        if (document.getElementById('finanzierung-con')) {
          document.getElementById('netto-betrag').innerHTML = (response.zinsen.nettoBetrag)
          document.getElementById('monats-rate').innerHTML = (response.zinsen.monatsRate)
          document.getElementById('finn-gesamt').innerHTML = (response.zinsen.gesamtbetrag)
          document.getElementById('raten-monate').innerHTML = (response.zinsen.laufzeit)
          document.getElementById('laufzeit').value = response.zinsen.laufzeit
        }
      }
    })
  }
}

function hasClassF (element, className) {
  do {
    if (element.classList && element.classList.contains(className)) {
      return true
    }
    element = element.parentNode
  } while (element)
  return false
}

var sendConfig = function (e) {
  e.preventDefault(e)
  data.modus = 'sendMail'
  data.zusatz = []
  let selected = document.getElementsByClassName('zusatz__list__item is--selected')
  if (selected.length) {
    for (let i = 0; i < selected.length; i++) {
      data.zusatz.push(selected[i].getAttribute('data-id'))
    }
  }
  data.name = document.getElementById('conf-name').value
  data.mail = document.getElementById('conf-mail').value
  data.tel = document.getElementById('conf-tel').value
  data.betreff = document.getElementById('conf-betreff').value
  data.text = document.getElementById('conf-text').value

  data.fin = document.getElementById('conf-fin').checked
  data.summe = document.getElementById('conf-summe').value
  if (document.getElementById('conf-datenschutz').checked !== true) {
    showStatus({
      message: 'Bitte bestätige den Datenschutz!',
      status: 'error'
    })
    return false
  }
  if (data.fID !== '') {
    xhr({
      body: JSON.stringify(data),
      uri: 'ajax.php',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    }, (err, res, body) => {
      if (err) {
        console.log(err)
      } else {
        let response = JSON.parse(body)
        console.log(response)
        showStatus(response)
      }
    })
  }
  return false
}

function showStatus (s) {
  if (s.status !== '') {
    let statusMessage = document.getElementById('conf-status')
    statusMessage.classList.add(s.status)
    statusMessage.innerText = s.message
    jump('.status-message', {
      offset: -200
    })
  }
}

export default function initConfiguration () {
  step1()
  forwardButton()

  document.body.addEventListener('click', function (evt) {
    if (hasClassF(evt.target, 'kredit-calc')) {
      updateFin()
    }
  })

  document.body.addEventListener('click', function (evt) {
    if (hasClassF(evt.target, 'conf-fin')) {
      document.getElementById('summe-fin').classList.toggle('is--hidden')
    }
  })

  var mainConf = document.getElementById('config-main')
  if (mainConf) {
    if (mainConf.classList.contains('is--loading')) {
      data.fID = mainConf.getAttribute('data-fid')
      data.fMarke = mainConf.getAttribute('data-fmarke')
      data.fKategorie = mainConf.getAttribute('data-fkategorie')
      document.getElementById('step-1').classList.add('is--hidden')
      step3Event()
    }
  }
}
