import xhr from 'xhr'
import 'core-js/fn/object/assign'
import noUiSlider from 'nouislider'
import initModal from './modal'

let minPrice = 20000
let maxPrice = 120000

let data = {
  fMarke: [],
  fHersteller: [],
  fKategorie: [],
  fLaenge: {
    min: 540,
    max: 745
  },
  fPriceNumber: {
    min: minPrice,
    max: maxPrice
  },
  fSchlafplaetze: 2,
  fPersonen: 2,
  fErstzulassung: {
    von: '',
    bis: ''
  },
  fGebraucht: 1,
  fNeuwagen: 1,
  fType: 'BESTAND',
  isSofort: false,
  modus: 'filterListing'
}

function sendFilter () {
  data.fType = document.getElementById('ftype').value

  window.sessionStorage.setItem('filterData', JSON.stringify(data))
  xhr({
    body: JSON.stringify(data),
    uri: 'ajax.php',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  }, (err, res, body) => {
    if (err) {
      console.log(err)
    } else {
      let response = JSON.parse(body)

      // console.log(response.html)
      if (document.getElementById('listing-cars')) {
        document.getElementById('listing-cars').innerHTML = response.html
      }

      if (document.getElementById('numberofresults')) {
        document.getElementById('numberofresults').innerText = response.rescount === 1 ? response.rescount + ' Ergebnis' : response.rescount + ' Ergebnisse'
      }

      initModal()
    }
  })
}

export default function initFilter () {
  if (document.getElementById('ftype')) {
    if (window.sessionStorage.getItem('filterData')) {
      var dataNew = JSON.parse(window.sessionStorage.getItem('filterData'))
      var fType = document.getElementById('ftype').value
      if (fType === dataNew.fType) {
        data = Object.assign(data, dataNew)
      }
    }
  }

  // Marken
  let _marke = document.getElementsByClassName('marke')
  if (_marke.length) {
    for (let i = _marke.length - 1; i >= 0; i--) {
      _marke[i].removeEventListener('click', function () {
      })
      _marke[i].addEventListener('click', function (evt) {
        data.fMarke = []
        for (let z = _marke.length - 1; z >= 0; z--) {
          if (_marke[z].checked === true) {
            data.fMarke.push(_marke[z].value)
          }
          if (_marke[z].id === 'ausstattung-pla') {
            if (_marke[z].checked === true) {
              document.getElementById('pla-typen').classList.remove('hidden')
            } else {
              document.getElementById('pla-typen').classList.add('hidden')
            }
          }
        }
        sendFilter()
      })
    }
  }
  //  Typen
  let _typen = document.getElementsByClassName('zusatzausstattung')
  if (_typen.length) {
    for (let i = _typen.length - 1; i >= 0; i--) {
      _typen[i].removeEventListener('click', function () {
      })
      _typen[i].addEventListener('click', function (evt) {
        data.fHersteller = []
        for (let z = _typen.length - 1; z >= 0; z--) {
          if (_typen[z].checked === true) {
            data.fHersteller.push(_typen[z].value)
          }
        }
        sendFilter()
      })
    }
  }

  //

  //  category
  // let _category = document.getElementsByClassName('listing-filter-category-box')
  // if (_category.length) {
  //   for (let i = _category.length - 1; i >= 0; i--) {
  //     _category[i].removeEventListener('click', function () {
  //     })
  //     _category[i].addEventListener('click', function (evt) {
  //       evt.preventDefault()
  //       for (let z = _category.length - 1; z >= 0; z--) {
  //         _category[z].classList.remove('aktiv')
  //       }
  //       this.classList.add('aktiv')
  //       data.fKategorie = this.getAttribute('data-value')
  //       sendFilter()
  //       return false
  //     })
  //   }
  // }

  // Schlaf
  let schlafSelect = document.getElementById('schlaf')
  if (schlafSelect) {
    schlafSelect.addEventListener('change', function () {
      data.fSchlafplaetze = schlafSelect.options[schlafSelect.selectedIndex].value
      sendFilter()
    })
    schlafSelect.value = data.fSchlafplaetze
  }

  // Sitz
  let sitzSelect = document.getElementById('sitz')
  if (sitzSelect) {
    sitzSelect.addEventListener('change', function () {
      data.fPersonen = sitzSelect.options[sitzSelect.selectedIndex].value
      sendFilter()
    })
    sitzSelect.value = data.fPersonen
  }

  // Länge
  let handlesSlider = document.getElementById('filter-laenge')

  if (handlesSlider) {
    noUiSlider.create(handlesSlider, {
      start: [data.fLaenge.min, data.fLaenge.max],
      range: {
        'min': [540],
        'max': [745]
      }
    })

    handlesSlider.noUiSlider.on('update', function (value) {
      let minLaenge = value[0]
      let maxLaenge = value[1]
      // update view
      document.getElementById('min-laenge-value').innerText = minLaenge
      document.getElementById('max-laenge-value').innerText = maxLaenge
    })

    handlesSlider.noUiSlider.on('change', function (value) {
      data.fLaenge.max = value[1]
      data.fLaenge.min = value[0]
      sendFilter()
    })
  }

  // preis range slider
  let preisSlider = document.getElementById('filter-preis')
  if (preisSlider) {
    noUiSlider.create(preisSlider, {
      start: [data.fPriceNumber.min, data.fPriceNumber.max],
      range: {
        'min': minPrice,
        'max': maxPrice
      },
      step: 1000,
      // eslint-disable-next-line
      format: wNumb({
        decimals: 2,
        thousand: '.',
        suffix: ' €'
      })
    })

    preisSlider.noUiSlider.on('update', function (value) {
      let minPreis = value[0]
      let maxPreis = value[1]
      // update view
      document.getElementById('min-preis-value').innerText = minPreis
      document.getElementById('max-preis-value').innerText = maxPreis
    })

    preisSlider.noUiSlider.on('change', function (value) {
      let minPreis = value[0]
      let maxPreis = value[1]

      data.fPriceNumber.min = minPreis.replace('.', '').replace('€', '')
      data.fPriceNumber.max = maxPreis.replace('.', '').replace('€', '')

      sendFilter()
    })

    // erstzulassung
    let ezFrom = document.getElementById('erstzulassung-von')
    if (ezFrom) {
      ezFrom.addEventListener('change', function () {
        data.fErstzulassung.von = ezFrom.options[ezFrom.selectedIndex].value
        sendFilter()
      })
      ezFrom.value = data.fErstzulassung.von
    }
    let ezTo = document.getElementById('erstzulassung-bis')
    if (ezTo) {
      ezTo.addEventListener('change', function () {
        data.fErstzulassung.bis = ezTo.options[ezTo.selectedIndex].value
        sendFilter()
      })
      ezTo.value = data.fErstzulassung.bis
    }

    // neu / gebraucht
    let fNeuwagen = document.getElementById('filter-neuwagen')
    if (fNeuwagen) {
      fNeuwagen.addEventListener('click', function (e) {
        if (e.target.checked) {
          data.fNeuwagen = 1
        } else {
          data.fNeuwagen = 0
        }
        sendFilter()
      })
      fNeuwagen.checked = data.fNeuwagen
    }

    let fGebraucht = document.getElementById('filter-gebrauchtwagen')
    if (fGebraucht) {
      fGebraucht.addEventListener('click', function (e) {
        if (e.target.checked) {
          data.fGebraucht = 1
        } else {
          data.fGebraucht = 0
        }
        sendFilter()
      })
      fGebraucht.checked = data.fGebraucht
    }
    let isSofort = document.getElementById('filter-sofort')
    if (isSofort) {
      isSofort.addEventListener('click', function (e) {
        if (e.target.checked) {
          data.isSofort = 1
        } else {
          data.isSofort = 0
        }
        sendFilter()
      })
      isSofort.checked = data.isSofort
    }
  }

  // let _schlaf = document.getElementsByClassName('schlaf')
  // if (_schlaf.length) {
  //   for (let i = _schlaf.length - 1; i >= 0; i--) {
  //     _schlaf[i].removeEventListener('click', function () {
  //     })
  //     _schlaf[i].addEventListener('click', function (evt) {
  //       data.fSchlafplaetze = []
  //       for (let z = _schlaf.length - 1; z >= 0; z--) {
  //         if (_schlaf[z].checked === true) {
  //           data.fSchlafplaetze.push(_schlaf[z].value)
  //         }
  //       }
  //       sendFilter()
  //     })
  //   }
  // }
  // let _sitz = document.getElementsByClassName('sitz')
  // if (_sitz) {
  //   _sitz.addEventListener('change', function() {
  //     data.fPersonen = _sitz.value
  //     sendFilter()
  //   })
  // }
  // if (_sitz.length) {
  //   for (let i = _sitz.length - 1; i >= 0; i--) {
  //     _sitz[i].removeEventListener('click', function () {
  //     })
  //     _sitz[i].addEventListener('click', function (evt) {
  //       data.fPersonen = []
  //       for (let z = _sitz.length - 1; z >= 0; z--) {
  //         if (_sitz[z].checked === true) {
  //           data.fPersonen.push(_sitz[z].value)
  //         }
  //       }
  //       sendFilter()
  //     })
  //   }
  // }

  let checkboxes = document.querySelectorAll('.filter-main--bauart input[type="checkbox"]')
  for (var i = 0; i < checkboxes.length; i++) {
    checkboxes[i].addEventListener('click', function (e) {
      let iconBox = e.target.previousElementSibling
      if (e.target.checked) {
        iconBox.classList.add('active')
        data.fKategorie.push(e.target.value)
        sendFilter()
      } else {
        iconBox.classList.remove('active')
        let index = data.fKategorie.indexOf(e.target.value)
        data.fKategorie.splice(index, 1)
        sendFilter()
      }
    })
    if (data.fKategorie.indexOf(checkboxes[i].value) !== -1) {
      let iconBox = checkboxes[i].previousElementSibling
      iconBox.classList.add('active')
      checkboxes[i].setAttribute('checked', 'checked')
    }
  }

  // marken
  let markenCheckbox = document.querySelectorAll('.filter-main--modell input[type="checkbox"]')
  for (var j = 0; j < markenCheckbox.length; j++) {
    markenCheckbox[j].addEventListener('click', function (e) {
      let imgBox = e.target.previousElementSibling
      if (e.target.checked) {
        imgBox.classList.add('active')
        data.fMarke.push(e.target.value)
      } else {
        imgBox.classList.remove('active')
        let index = data.fMarke.indexOf(e.target.value)
        data.fMarke.splice(index, 1)
      }
      sendFilter()
    })
    if (data.fMarke.indexOf(markenCheckbox[j].value) !== -1) {
      let imgBox = markenCheckbox[j].previousElementSibling
      imgBox.classList.add('active')
      markenCheckbox[j].setAttribute('checked', 'checked')
    }
  }

  let searchBtn = document.getElementById('start-search-btn')
  if (searchBtn) {
    searchBtn.addEventListener('click', function (e) {
      sendFilter()
    })
  }

  if (preisSlider) {
    sendFilter()
  }
}
