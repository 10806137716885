export default function init () {
  let btn = document.getElementById('detail--more')
  if (btn) {
    btn.addEventListener('click', function (e) {
      e.preventDefault()
      let detailBox = e.target.offsetParent
      detailBox.classList.toggle('is--open')
      if (detailBox.classList.contains('is--open')) {
        btn.innerText = 'Weniger anzeigen'
      } else {
        btn.innerText = 'Mehr anzeigen'
      }
    })
  }
}
